import React, {Suspense, useState, useEffect} from 'react';
import {
    Switch,
    Route,
    Redirect,
    RouteProps,
    useHistory,
} from 'react-router-dom';

import authInterceptor from 'metrics/services/AuthInterceptor';

import {SpinnerLoader} from 'metrics/components/Common/Loader/Loader';
import Base from 'metrics/components/Layout/Base';

import StorageAuth from 'metrics/services/storage/Auth';

// Role Related
import AppPaths, {
    getRoutesByRole,
    getUnauthorizedRoutes,
    PATHS,
} from './AppPaths';
import MetaMenu, {ISidebarMenuItem} from './MetaMenu';
import PermissionProvider from 'metrics/providers/permissionProvider';
import EUserRol from 'metrics/types/EUserRol';
import UserProvider from 'metrics/providers/userProvider';
import UserService from 'metrics/services/http/User';
import TUser from 'metrics/types/TUser';
import HttpStorageI18n from '../metrics/services/storage/Language';
import i18n from 'i18next';

const waitFor = (Tag: React.LazyExoticComponent<any>) => (props: any) => {
    return <Tag {...props} />;
};

authInterceptor();
const Auth = new StorageAuth();

export default ({location}: RouteProps) => {
    const isLoggedIn = Auth.isTokenAlive(Auth.getSession());
    const [permissionAccess, setPermissionAccess]: string | any = useState([]);
    const [user, setUser]: TUser | any = useState(null);
    const history = useHistory();
    let routes: any = [];
    let redirectTo = AppPaths.login;

    useEffect(() => {
        const handleUser = async () => {
            const token = Auth.getAccessToken();
            if (isLoggedIn && token) {
                try {
                    const response = await new UserService().me();
                    if (response && response.data) {
                        if (response.data?.language) {
                            await i18n.changeLanguage(response.data.language);
                            await HttpStorageI18n.saveLanguage(response.data.language);
                        }

                        if (!response.data.company) {
                            return history.push(PATHS.login);
                        }
                        setUser(response.data);

                        if (!response.data.company.activeSubscription) {
                            return history.push(PATHS.login);
                        }
                    }
                }
                catch (err) {
                    console.log(err);
                }
            }
        };
        handleUser().then();
    }, [permissionAccess]);

    if (!isLoggedIn) {
        routes = getUnauthorizedRoutes();
    }
    else {
        redirectTo = AppPaths.home;
        routes = getRoutesByRole([EUserRol.ADMIN]);
    }

    const routesComponents: JSX.Element[] = routes.map((route: any) => (
        <Route
            path={route.path}
            exact
            component={waitFor(route.component)}
            key={route.path}
        />
    ));
    const metaMenuRoutes: ISidebarMenuItem[] = routes.flatMap((route: any) =>
        MetaMenu.filter((menu) => menu.path === route.path)
    );

    const fallback = (
        <div
            className="page-loader d-flex flex-row justify-content-center"
            style={{height: '100vh'}}
        >
            <SpinnerLoader width={'150px'} height={'150px'} color="black"/>
        </div>
    );

    return (
        <PermissionProvider.Provider
            value={[permissionAccess, setPermissionAccess]}
        >
            <UserProvider.Provider value={[user, setUser]}>
                <Base menu={metaMenuRoutes}>
                    <div>
                        <Suspense fallback={fallback}>
                            <Switch location={location}>
                                {routesComponents}
                                <Redirect to={redirectTo}/>
                            </Switch>
                        </Suspense>
                    </div>
                </Base>
            </UserProvider.Provider>
        </PermissionProvider.Provider>
    );
};
