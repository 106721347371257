import React from 'react';

interface IButton {
  type: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | string;
  children?: any;
  className?: any;
  onClick?(): void;
  onHover?(): void;
  disabled?: boolean;
  textColorClass?: string,
}
const Button = (props: IButton) => {
    const {
        type,
        children,
        onClick,
        onHover,
        className = '',
        disabled = false,
        textColorClass = 'text-white',
    } = props;

    const _onClick = () => {
        if (onClick) {
            onClick();
        }
    };

    const _onHover = () => {
        if (onHover) {
            onHover();
        }
    };

    const t = `bg-${type}`;
    const bgColor = type ? `${t}` : '';
    const buttonClass = `inline-flex ${bgColor} justify-center px-4 py-3 text-sm font-medium 
        ${textColorClass} rounded-md hover:bg-opacity-50 focus:outline-none focus-visible:ring-2 
        focus-visible:ring-white focus-visible:ring-opacity-75
        ${disabled ? 'bg-opacity-50 cursor-not-allowed' : ''} ${className}`;
    return (
        <button
            disabled={disabled}
            className={buttonClass}
            onClick={_onClick}
            onMouseEnter={_onHover}
        >
            {children}
        </button>
    );
};

export default Button;
