import Axios from 'axios';
import {handleError} from './utils';
import {languageDefault} from '../../utils/Language';
import Http from './Http';

type TLogin = {
	accessToken: string;
	refreshToken: string;
	userLanguage?: string;
};

type TRefreshToken = {
	accessToken: string;
};

export default class Auth extends Http {
    constructor() {
        super('');
    }

    async loginWithPassword(email: string, password: string): Promise<TLogin> {
        return this.login('password', password, email);
    }

    async loginWithSecurityCode(
        email: string,
        securityCode: string
    ): Promise<TLogin> {
        return this.login('security_code', securityCode, email);
    }

    async loginWithGoogleToken(
        googleTokenId: string
    ): Promise<TLogin> {
        return this.login('google_id', googleTokenId);
    }

    private async login(
        securityType: 'password' | 'security_code' | 'google_id',
        value: string,
        email?: string,
    ) {
        let result: any;
        try {
            const req: any = {};
            switch (securityType) {
            case 'google_id':
                req['grant_type'] = 'social';
                req['social'] = 'google';
                req['token'] = value;
                break;
            case 'password':
                req['grant_type'] = 'password';
                req['email'] = email;
                req['password'] = value;
                break;
            case 'security_code':
                req['grant_type'] = 'security_code';
                req['email'] = email;
                req['security_code'] = value;
                break;
            }
            result = await Axios.post(`/tokens`, req, this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
        const userLanguage =
			result.data.userLanguage || languageDefault.acronym;
        return {
            ...result.data,
            userLanguage,
        };
    }

    async refreshToken(refreshTokenString: string): Promise<TRefreshToken> {
        let result: any;
        try {
            result = await Axios.post(
                `/refresh`,
                {refreshToken: refreshTokenString},
                this.requestConfig
            );
        }
        catch (err) {
            handleError(err);
        }

        const {accessToken} = result.data;

        return {
            accessToken,
        };
    }

    async resetPassword(email: string) {
        try {
            return await Axios.delete(`/users/password`, {
                ...this.requestConfig,
                data: {email: email},
            });
        }
        catch (err) {
            handleError(err);
        }
    }

    async newSecurityCode(email: string) {
        try {
            return await Axios.post(
                `/tokens/security-code`,
                {email: email},
                this.requestConfig,
            );
        }
        catch (err) {
            handleError(err);
        }
    }
}
