import React, {Component} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {WithTranslation, withTranslation} from 'react-i18next';
import i18n from 'i18next';

import Routes from './Routes';

import HttpStorageI18n from './metrics/services/storage/Language';
import {languagesList, languageDefault} from './metrics/utils/Language';

import './DefaultStyles';
import {GoogleOAuthProvider} from '@react-oauth/google';

class App extends Component<WithTranslation, any> {
    componentDidMount() {
        if (!HttpStorageI18n.getItem('language')) {
            let userLang = languageDefault.acronym;
            const navigatorLanguage = navigator.language.slice(0, 2);
            const objFindLang = languagesList.find(
                (item: any) => item.acronym === navigatorLanguage
            );
            if (objFindLang) userLang = objFindLang.acronym;

            HttpStorageI18n.saveLanguage(userLang);
            i18n.changeLanguage(userLang, () => {
                window.location.reload();
            }).then();
        }
    }

    render() {
        const basename = '/';

        return (
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string} onScriptLoadError={() => {}} onScriptLoadSuccess={() => {}}>
                <BrowserRouter basename={basename}>
                    <Routes />
                </BrowserRouter>
            </GoogleOAuthProvider>
        );
    }
}

export default withTranslation()(App);
