import EsTranslations from '../i18n/es/translations.json';
import EnTranslations from '../i18n/en/translations.json';

export const t = (key: string) => {
    const userLanguage = 'en'; // TO DO: Get from user session;
    const data: any = {
        es: EsTranslations,
        en: EnTranslations,
    };

    return data[userLanguage][key] || key;
};

export default t;
