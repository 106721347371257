const defaultAcronym = 'en';
export const languagesList: any = [
    {acronym: 'cat', title: 'languagesList-cat'},
    {acronym: 'es', title: 'languagesList-es'},
    {acronym: 'en', title: 'languagesList-en'},
    {acronym: 'pt', title: 'languagesList-pt'},
];

export const languageDefault: any = languagesList.find(
    (item: any) => item.acronym === defaultAcronym
);
