
export interface ISidebarMenuItem {
  heading?: string;
  name?: string;
  icon?: string;
  translate?: string;
  path?: string;
  label?: {
    value: string | number;
    color: string;
  };
  submenu?: Array<ISidebarMenuItem>;
}

const Menu: Array<ISidebarMenuItem> = [];

export default Menu;
