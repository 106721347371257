export default class RegexLibrary {
  public static email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static password = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  public static float = /^(\d+(?:[\.\,]\d{1,2})?)$/;
  public static onlyNumbers = /^[0-9]*$/;
  public static textNumberSpaceLine = /[^A-Za-z0-9\s-._]/g;
  public static url = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  public static urlHttps = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,24}\b([-a-zA-Z0-9()@:%_+.~#?&\/=]*)/
  public static code = /^[A-Z0-9]*$/i
  public static phone = /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/
}
