import React, {useState, Fragment, useContext} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import ModalProvider from 'metrics/providers/modalProvider';
import Button from '../Button/Button';
import {useTranslation} from 'react-i18next';

interface IConfirmModalFooterProps {
    closeModal(): void;
    onConfirm(): void;
    cancelLabel: string;
    confirmLabel: string;
}
const ModalFooter = (props: IConfirmModalFooterProps) => {
    const {t} = useTranslation();
    const {closeModal, onConfirm, cancelLabel, confirmLabel} = props;
    return (
        <div className="mt-4 flex flex-row space-x-4 justify-center items-center">
            <Button
                type="tertiary"
                onClick={closeModal}
                className="w-1/2"
            >
                {t(cancelLabel)}
            </Button>

            <Button
                type="transparent"
                onClick={onConfirm}
                className="w-1/2 border-solid border-2 border-tertiary outline-tertiary text-tertiary hover:bg-tertiary hover:bg-opacity-50"
            >
                {t(confirmLabel)}
            </Button>
        </div>
    );
};

interface IConfirmModalProps {
    title?: string;
    cancelLabel: string;
    confirmLabel: string;
    onConfirm(): void;
}
const ConfirmModal = (props: IConfirmModalProps) => {
    const {cancelLabel, confirmLabel, onConfirm, title} = props;
    const [isOpen, setIsOpen] = useState(true);
    // eslint-disable-next-line
    const [showModal, setShowModal] = useContext(ModalProvider);
    const {t} = useTranslation();

    const closeModal = () => {
        setShowModal(false);
        setIsOpen(false);
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto bg-tertiary bg-opacity-80"
                onClose={closeModal}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
            &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className="inline-block w-full max-w-xl py-6 px-10 mb-40 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                            {title && (
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-primary pb-3 text-center"
                                >
                                    {t(title)}
                                </Dialog.Title>
                            )}
                            <ModalFooter closeModal={closeModal} onConfirm={onConfirm} confirmLabel={confirmLabel} cancelLabel={cancelLabel} />
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ConfirmModal;
