/* eslint-disable */
import Input from 'metrics/components/Common/Input/Input';
import Modal from 'metrics/components/Common/Modal/Modal';
import Notification from 'metrics/components/Common/Notification/Notification';
import useFormValidations from 'metrics/hooks/useFormValidations.hook';
import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';
import ErrorException from 'metrics/services/exception/ErrorException';
import AuthStorage from 'metrics/services/storage/Auth';
import UserService from 'metrics/services/http/User';
import AuthService from 'metrics/services/http/Auth';

enum EForgotPasswordSteps {
    inputEmail,
    verficationCode,
    inputNewPassword
}

const ModalForgotPassword = (props: any) => {
    const {setShowModal} = props;
    const {t}: any = useTranslation();
    const {onInputChange, validateForm, getValue} =
    useFormValidations({
        email: {validations: ['isEmail'], feedbackMessage: 'invalidMail'},
        verificationCode: {validations: ['required'], feedbackMessage: 'invalidVerificationCode'},
        newPassword: {validations: ['required', 'password'], feedbackMessage: 'invalidPassword' },
        newPassword2: {validations: [{sameAs: 'newPassword'}], feedbackMessage: 'invalidPassword'}
    });
    const [currentStep, setCurrentStep] = useState(EForgotPasswordSteps.inputEmail);

    const submitSendEmail = async () => {
        try {
            const res = await new AuthService().resetPassword(getValue('email'));
            if (res) {
                Notification.display('success', t('emailForgotPasswordSend'));
                setCurrentStep(EForgotPasswordSteps.verficationCode);
            }
        }
        catch (err) {
            const error = await new ErrorException(err.message);
            Notification.displayException(error);
        }
    };

    const submitVerificationCode = async () => {
        try {
            const email = getValue('email');
            const token = getValue('verificationCode');
            const res = await new AuthService().loginWithSecurityCode(email, token);
            const AuthStore = new AuthStorage();
            AuthStore.saveSession({
                accessToken: res.accessToken,
                loginAt: new Date(),
            });
            setCurrentStep(EForgotPasswordSteps.inputNewPassword);
        }
        catch (err) {
            const error = await new ErrorException(err.message);
            Notification.displayException(error);
        }
    }

    const submitNewPassword = async () => {
        try {
            const newPassword = getValue('newPassword');
            const newPassword2 = getValue('newPassword2');
            if (newPassword == newPassword2) {
                await new UserService().update({
                    password: newPassword,
                });
                Notification.display("success", t("passwordUpdated"));
                setShowModal('');
            }
            else {
                Notification.display("error", t("inputPassword2Help"))
            }
        }
        catch (err) {
            Notification.displayException(err);
        }
    }

    const submit = async () => {
        if (currentStep == EForgotPasswordSteps.inputEmail) {
            submitSendEmail();
        }
        else if (currentStep == EForgotPasswordSteps.verficationCode) {
            submitVerificationCode();
        }
        else if (currentStep == EForgotPasswordSteps.inputNewPassword) {
            submitNewPassword();
        }
    };

    return (
        <Modal title={t('forgotPassword')} showBtnSubmit={true} onSubmit={submit}>
            {currentStep == EForgotPasswordSteps.inputEmail && <div>
                <p className="text-gray-400">
                    {t('forgotPasswordText')}
                </p>
                <div className="mt-6">
                    <Input
                        label={t('email')}
                        name="forgot-email"
                        onChange={(v: any) => {
                            onInputChange('email', v);
                        }}
                    />
                </div>
            </div>}
            {currentStep == EForgotPasswordSteps.verficationCode && <div>
                <p className="text-gray-400">
                    {t('forgotPasswordVerificationCode')}
                </p>
                <div className="mt-6">
                    <Input
                        label={t('verficationCode')}
                        name="verificationCode"
                        onChange={(v: any) => {
                            onInputChange('verificationCode', v);
                        }}
                    />
                </div>
            </div>}
            {currentStep == EForgotPasswordSteps.inputNewPassword && <div>
                <p className="text-gray-400">
                    {t('forgotPasswordNewPassword')}
                </p>
                <div className="mt-6">
                    <Input
                        label={t('password')}
                        name="newPassword"
                        onChange={(v: any) => {
                            onInputChange('newPassword', v);
                        }}
                        type="password"
                    />
                </div>
                <div className="mt-6">
                    <Input
                        label={t('password2')}
                        name="newPassword2"
                        onChange={(v: any) => {
                            onInputChange('newPassword2', v);
                        }}
                        type="password"
                    />
                </div>
            </div>}
        </Modal>
    );
};
export default ModalForgotPassword;
