import React from 'react';
import ConfirmModal from '../Common/Modal/ConfirmModal';

const ModalCancelSubscription = (props: any) => {
    const {data} = props;
    return (
        <ConfirmModal title={'askCancelSubscription'} onConfirm={data} confirmLabel={'confirmCancelSubscriptionLabel'} cancelLabel={'cancelCancelSubscriptionLabel'} />
    );
};
export default ModalCancelSubscription;
