import Axios from 'axios';
import Http from './Http';
import {handleError} from './utils';

type TUserRequest = {
    'id'?: string
    'avatar_path'?: string,
    'email'?: string,
    'birth_date'?: string,
    'password'?: string,
    'username'?: string,
    'gender'?: string,
    'from_address'?: string,
    'live_address'?: string,
    'email_notification'?: boolean,
    'push_notification'?: boolean,
    'name'?: string,
    'web'?: string,
    'phone'?: string,
    'language'?: string,
    'referral'?: string|null|undefined,
}

type TGoogleUserRequest = {
    'tokenId': string
}

export default class User extends Http {
    constructor() {
        super('');
    }

    async me(): Promise<any> {
        try {
            return await Axios.get(`/me`, this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
    }

    async create(data: TUserRequest) {
        try {
            return await this.post(`/users`, data, this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
    }

    async createWithGoogleToken(data: TGoogleUserRequest) {
        try {
            return await this.post('/users', {
                social: 'google',
                token: data.tokenId,
            }, this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
    }

    async update(data: TUserRequest) {
        try {
            return await this.patch(`/me`, data, this.requestConfig);
        }
        catch (err) {
            handleError(err);
        }
    }
}
