type TKeys = 'language' | 'languageBackend';

export class Language {
    public saveLanguage(language: string) {
        this.setItem('language', language);
    }

    public saveLanguageBackend(languageBackend: string) {
        this.setItem('languageBackend', languageBackend);
    }

    public removeLanguage() {
        this.removeItem('language');
    }

    public removeLanguageBackend() {
        this.removeItem('languageBackend');
    }

    public getCurrentLanguage() {
        return this.getItem('language') || 'none';
    }

    public getItem(key: TKeys): string | null {
        return localStorage.getItem(`i18n.${key}`);
    }

    private removeItem(key: TKeys) {
        localStorage.removeItem(`i18n.${key}`);
    }

    private setItem(key: TKeys, value: string) {
        localStorage.setItem(`i18n.${key}`, value);
    }
}

export default new Language();
