import React, {lazy} from 'react';
import EUserRol from 'metrics/types/EUserRol';

interface IRoute {
  path: string;
  component: React.Component | any;
  roles: EUserRol[];
}

const Welcome = lazy(() => import('pages/Welcome/Welcome'));
const Home = lazy(() => import('pages/Home/Home'));
const CampaignForm = lazy(() => import('pages/CampaignForm/CampaignFormWrapper'));
const Campaign = lazy(() => import('pages/Campaign/CampaignWrapper'));

export const PATHS = {
    login: '/welcome',
    home: '/home',
    newCampaign: '/campaign/new',
    campaign: '/campaign/:id',
    editCampaign: '/campaign/:id/edit',
    duplicateCampaign: '/campaign/:id/duplicate',
};
export default PATHS;

export const AUTH_ROUTES: IRoute[] = [
    {
        path: PATHS.home,
        component: Home,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.newCampaign,
        component: CampaignForm,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.campaign,
        component: Campaign,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.editCampaign,
        component: CampaignForm,
        roles: [EUserRol.ADMIN],
    },
    {
        path: PATHS.duplicateCampaign,
        component: CampaignForm,
        roles: [EUserRol.ADMIN],
    },
];

export const ROUTES: IRoute[] = [
    {
        path: PATHS.login,
        component: Welcome,
        roles: [],
    },
];

export function getRoutesByRole(roles: EUserRol[]): IRoute[] {
    const ret: IRoute[] = [];

    AUTH_ROUTES.forEach((route: IRoute) => {
        let i = 0;
        let hasRole = false;
        while (!hasRole && i < roles.length) {
            hasRole = route.roles.indexOf(roles[i]) !== -1;
            i++;
        }
        if (hasRole) {
            ret.push(route);
        }
    });

    return ret;
}

export function getUnauthorizedRoutes(): IRoute[] {
    return ROUTES;
}
