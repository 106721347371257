import React from 'react';

export interface ModalProviderData {
  modalName: string;
  data?: any;
}

const ModalProvider = React.createContext<[ModalProviderData | string, any]>([
    {modalName: ''},
    {},
]);

export default ModalProvider;
